.container {
  margin: var(--spacing0750);
}

.select {
  width: 240px;
}

.failedRecordMessage {
  padding-bottom: var(--spacing1000);
  color: var(--blockingRed);
}
