.pageContent {
  display: flex;
  flex-direction: column;
}

.reinviteBtnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.tableSmallLabel {
  font-size: 12px;
}
