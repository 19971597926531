.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  padding: 0%;
  text-align: left;
  position: relative;
  background: var(--white);
  &.clickable {
    cursor: pointer;
  }
  & .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 24px;
    background: var(--gray300);
    padding: 0 var(--spacing0500);
  }
  & .disabled {
    background: var(--gray400);
    color: var(--white);
  }
  & .bodyWrapper {
    padding: var(--spacing0500);
    flex: 1;
    overflow: hidden;
    & .title {
      margin-bottom: var(--spacing0125);
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & .description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.disabled {
  &.clickable {
    cursor: not-allowed;
  }
}

.shortcutPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background: var(--white);
  align-items: stretch;
  & .iconWrapper {
    background: var(--gray300);
    padding: 0 var(--spacing0500);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 24px;
    & i {
      display: flex;
    }
  }
  & .title {
    flex: 1;
    font-weight: bold;
    padding: var(--spacing0500);
    font-size: var(--placeholder-title-font-size);
    display: flex;
    align-items: center;
  }
}
