.card {
  height: 100%;
  border: none;
}

.header {
  border-bottom: none;
  padding: var(--spacing1000);
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing1000) var(--spacing1000);
  &.emptyContent {
    overflow: hidden;
  }
}
