.error {
  width: 100%;
}

.input {
  position: relative;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  width: 450px;

  & .field {
    width: 100%;
    margin: 0 0 var(--spacing1000) 0;
    &.noBottomMargin {
      margin-bottom: 0;
    }
  }
}

.btnLogin {
  margin: 0 auto;
}

.loader {
  margin: 0 auto;
}

.footer {
  display: flex;
  width: 100%;
  margin-top: var(--spacing1000);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.linkContainer {
  margin-top: var(--spacing1500);
}

@media screen and (--max-width-sm) {
  .form {
    width: 320px;
  }
}
