.container {
  display: flex;
  flex: 1 0;
  & .visibleWidgets {
    flex: 1;
    position: relative;
    & .gridLayout {
      min-height: 100%;
    }
  }
  & .widgetsSelector {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    box-sizing: border-box;
    transition: width 80ms ease-in-out;
    & .widgetsSelectorEmpty {
      display: none;
      color: var(--gray300);
    }
    & .tabsContainer {
      width: 100%;
      background: var(--black050);
      backdrop-filter: blur(5px);
      & .tabMenu {
        background: var(--gray800);
        box-shadow: 0 1px 5px var(--black020);
        color: var(--white);
      }
    }
    & .widgets {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      padding: var(--spacing1000);
    }
    & .widgetSelectorBtn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 24px;
      top: 50%;
      transform: translate(-100%, -50%);
      padding: 0;
      margin: 0;
      border-radius: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      color: var(--white);
      font-size: 1.5rem;
      background: var(--black050);
      backdrop-filter: blur(5px);
      cursor: pointer;
      &:focus {
        outline: 1px dashed var(--ink500);
        outline-offset: -3px;
      }
    }
    &.open {
      width: 460px;
      & .widgetsSelectorEmpty {
        display: flex;
      }
    }
  }
  & .empty {
    color: var(--ink500);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    align-items: center;
    & .emptyIcon {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }
  &.configuring {
    overflow: hidden;
    & .visibleWidgets {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.widgetWrapper {
  position: relative;
  box-shadow: var(--shadow2);
  &.droppingElem {
    box-shadow: none;
  }
  & .widgetInnerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.configuring {
      pointer-events: none;
      user-select: none;
      box-sizing: content-box;
    }
  }
  & .removeWidgetBtn {
    position: absolute;
    top: var(--spacing0500);
    right: var(--spacing0500);
  }
}

.droppableElement {
  --placeholder-height: 140px;
  --placeholder-title-font-size: 12px;
  border-radius: 3px;
  overflow: hidden;
  &.dragging {
    opacity: 0.5;
  }
  & .droppableElementInner {
    pointer-events: none;
    user-select: none;
    box-sizing: content-box;
  }
}

:global(.react-grid-item.react-grid-placeholder) {
  background: var(--gray800);
  opacity: 0.1;
}
