.container {
  height: var(--placeholder-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  & .timeline {
    display: flex;
    align-items: center;
    background: var(--gray300);
    padding: var(--spacing1000);
    box-sizing: border-box;
    flex: 1 0;
    & .icon {
      font-size: 24px;
      display: inline-flex;
    }
  }
  & .title {
    flex: 1 0;
    padding: var(--spacing1000);
    background: var(--white);
    font-weight: bold;
    font-size: var(--placeholder-title-font-size);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
